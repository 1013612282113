<template>
    <div>
        <template v-if="!isFamilyMemberMode">
            <v-card-title>Осигурен</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col md="2" class="pb-0">
                        <PidTypePicker v-model="currentPatient.personIdentification.pidTypeCode" disabled />
                    </v-col>

                    <v-col cols="12" md="5" class="pb-0">
                        <text-field
                            v-model="currentPatient.personIdentification.identifier"
                            disabled
                            label="Идентификатор"
                        />
                    </v-col>
                    <v-col cols="4" md="1" class="pb-0">
                        <text-field v-model="currentPatient.age" disabled label="Възраст" />
                    </v-col>
                    <v-col cols="4" md="2" class="pb-0">
                        <text-field v-model="currentPatient.rhif" disabled label="РЗОК" />
                    </v-col>
                    <v-col cols="4" md="2" class="pb-0">
                        <text-field v-model="currentPatient.rhifArea" disabled label="Здр. район" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4" class="py-0">
                        <text-field v-model="currentPatient.firstName" disabled label="Име" />
                    </v-col>
                    <v-col cols="4" class="py-0">
                        <text-field v-model="currentPatient.middleName" disabled label="Презиме" />
                    </v-col>
                    <v-col cols="4" class="py-0">
                        <text-field v-model="currentPatient.lastName" disabled label="Фамилия" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4" class="py-0">
                        <text-field v-model="currentPatient.address.cityName" disabled label="Нас. място" />
                    </v-col>
                    <v-col cols="8" md="4" class="py-0">
                        <text-field v-model="currentPatient.address.street" disabled label="Улица" />
                    </v-col>
                    <v-col cols="4" class="py-0">
                        <text-field v-model="currentPatient.address.streetNumber" disabled label="Номер" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4" class="py-0">
                        <text-field v-model="currentPatient.address.neighborhood" disabled label="Ж.к." />
                    </v-col>
                    <v-col cols="3" md="2" class="py-0">
                        <text-field v-model="currentPatient.address.block" disabled label="Блок" />
                    </v-col>
                    <v-col cols="3" md="2" class="py-0">
                        <text-field v-model="currentPatient.address.entrance" disabled label="Вход" />
                    </v-col>
                    <v-col cols="3" md="2" class="py-0">
                        <text-field v-model="currentPatient.address.floor" disabled label="Етаж" />
                    </v-col>
                    <v-col cols="3" md="2" class="py-0">
                        <text-field v-model="currentPatient.address.apartment" disabled label="Ап." />
                    </v-col>
                </v-row>

                <v-row>
                    <v-col><v-card-title class="pl-0">Месторабота</v-card-title></v-col>
                    <v-col>
                        <v-select
                            v-model="sickSheet.patientWorkplaceId"
                            :items="patientWorkplaces"
                            :item-text="workplacePosition"
                            item-value="id"
                            no-data-text="Няма въведена месторабота"
                            label="Избор на месторабота"
                            class="required"
                            :rules="[$validator.required]"
                        />
                    </v-col>
                    <v-col cols="auto">
                        <btn icon action="Edit" @click="openWorkplaceDialog" />
                    </v-col>

                    <v-dialog v-model="workplaceDialogIsVisible" persistent>
                        <v-card>
                            <v-card-title class="pa-0">
                                <v-toolbar flat>
                                    <v-toolbar-title>Избор на Месторабота</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <btn icon action="Close" @click="closeDialog" />
                                </v-toolbar>
                            </v-card-title>
                            <patient-workplace
                                :id="currentPatient.key.patientId"
                                :close-clicked="isCloseClicked"
                                @change="updateWorkplaces"
                            />
                        </v-card>
                    </v-dialog>
                </v-row>

                <text-field
                    v-model="workplace.company"
                    disabled
                    required
                    hint="предприятие, учреждение, организация, фирма"
                />
                <v-row>
                    <v-col cols="6" class="pb-0">
                        <CityPicker
                            v-model="workplace.cityId"
                            :required="Boolean(!workplace.foreignCity)"
                            label="гр."
                            disabled
                        />
                    </v-col>
                    <v-col cols="6" class="pb-0">
                        <text-field
                            v-model="workplace.foreignCity"
                            label="гр. извън България"
                            disabled
                            :required="!workplace.cityId"
                        ></text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="5" class="py-0">
                        <text-field v-model="workplace.street" disabled label="ул." />
                    </v-col>
                    <v-col cols="1" class="py-0">
                        <text-field v-model="workplace.streetNumber" disabled label="№" />
                    </v-col>
                    <v-col cols="6" class="py-0">
                        <text-field v-model="workplace.neighborhood" disabled label="ж.к." />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6" class="py-0">
                        <text-field v-model="workplace.profession" disabled label="Професия" />
                    </v-col>
                    <v-col cols="6" class="py-0">
                        <text-field v-model="workplace.position" disabled label="Длъжност" />
                    </v-col>
                </v-row>
            </v-card-text>
        </template>

        <template v-else>
            <v-card-title>Придружаващо лице</v-card-title>
            <v-card-text>
                <v-row>
                    <v-dialog v-model="choiceMakerDialogIsVisible" persistent max-width="50%">
                        <v-card>
                            <v-card-title>
                                За пациента има придружаващо лице от регистъра. Да се попълнят ли данните?
                            </v-card-title>
                            <v-card-actions>
                                <btn action="Back" @click="choiceMakerDialogIsVisible = false" />
                                <btn action="Fill" @click="populateWithChoiceMaker" />
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-row>
                <v-row v-if="familyMembers && familyMembers.length > 0">
                    <v-col cols="12" lg="6">
                        <dropdown
                            return-object
                            :items="familyMembers"
                            item-text="title"
                            label="За пациента има придружаващи лица от предходни БЛ"
                            @input="familyMemberChange"
                        ></dropdown>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="3" class="py-0">
                        <PidTypePicker v-model="familyMember.familyMemberPidTypeCode" required @blur="parseAgeGender" />
                    </v-col>

                    <v-col cols="12" md="3" class="py-0">
                        <text-field
                            v-model.trim="familyMember.familyMemberIdentifier"
                            required
                            label="Идентификатор"
                            :rules="[
                                $validator.identifier(
                                    familyMember.familyMemberPidTypeCode,
                                    familyMember.familyMemberIdentifier
                                )
                            ]"
                            @blur="parseAgeGender"
                        />
                    </v-col>

                    <v-col cols="4" md="3" class="py-0">
                        <dropdown
                            v-model="familyMember.familyMemberGenderCode"
                            :items="genders"
                            item-value="code"
                            label="Пол"
                            :disabled="isEgnFMPidTypeCode"
                            required
                        />
                    </v-col>

                    <v-col cols="4" md="3" class="py-0">
                        <text-field
                            v-model="familyMember.familyMemberAge"
                            :disabled="isEgnFMPidTypeCode"
                            required
                            label="Възраст"
                        />
                    </v-col>

                    <v-col cols="4" md="3" class="py-0">
                        <dropdown
                            v-model="rhifIdAndRhifAreaId.rhifId"
                            :items="rhifs"
                            no-data-text="Няма данни"
                            label="РЗОК"
                            disabled
                        />
                    </v-col>
                    <v-col cols="4" md="3" class="py-0">
                        <dropdown
                            v-model="rhifIdAndRhifAreaId.rhifAreaId"
                            :items="rhifAreas"
                            no-data-text="Няма данни"
                            label="Здравен район"
                            disabled
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4" class="py-0">
                        <text-field v-model="familyMember.familyMemberFirstName" label="Име" required />
                    </v-col>
                    <v-col cols="4" class="py-0">
                        <text-field v-model="familyMember.familyMemberMiddleName" label="Презиме" />
                    </v-col>
                    <v-col cols="4" class="py-0">
                        <text-field v-model="familyMember.familyMemberLastName" required label="Фамилия" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="6" class="py-0">
                        <CityPicker v-model="familyMember.familyMemberCityId" label="Нас. място" required />
                    </v-col>
                    <v-col cols="8" md="4" class="py-0">
                        <text-field v-model="familyMember.familyMemberStreet" label="Улица" />
                    </v-col>
                    <v-col cols="4" md="2" class="py-0">
                        <text-field v-model="familyMember.familyMemberStreetNumber" label="Номер" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4" class="py-0">
                        <text-field v-model="familyMember.familyMemberNeighborhood" label="Ж.к." />
                    </v-col>
                    <v-col cols="3" md="2" class="py-0">
                        <text-field v-model="familyMember.familyMemberBlock" label="Блок" />
                    </v-col>
                    <v-col cols="3" md="2" class="py-0">
                        <text-field v-model="familyMember.familyMemberEntrance" label="Вход" />
                    </v-col>
                    <v-col cols="3" md="2" class="py-0">
                        <text-field v-model="familyMember.familyMemberFloor" label="Етаж" />
                    </v-col>
                    <v-col cols="3" md="2" class="py-0">
                        <text-field v-model="familyMember.familyMemberApartment" label="Ап." />
                    </v-col>
                </v-row>
                <v-row>
                    <v-card-title class="pl-3">Месторабота</v-card-title>
                    <text-field
                        v-model="familyMember.familyMemberCompany"
                        required
                        hint="предприятие, учреждение, организация, фирма"
                    />
                </v-row>
                <v-row>
                    <v-col cols="12" md="4" class="py-0">
                        <CityPicker
                            v-model="familyMember.familyMemberWorkCityId"
                            label="гр."
                            :required="!familyMember.familyMemberWorkForeignCity"
                        />
                    </v-col>

                    <v-col cols="12" md="4" class="py-0">
                        <text-field
                            v-model="familyMember.familyMemberWorkForeignCity"
                            :required="!familyMember.familyMemberWorkCityId"
                            label="гр. извън България"
                        />
                    </v-col>

                    <v-col cols="12" md="4" class="py-0">
                        <text-field v-model="familyMember.familyMemberWorkStreet" label="ул." />
                    </v-col>

                    <v-col cols="2" md="1" class="py-0">
                        <text-field v-model="familyMember.familyMemberWorkStreetNumb" label="№" />
                    </v-col>

                    <v-col cols="10" md="3" class="py-0">
                        <text-field v-model="familyMember.familyMemberWorkNeighborhood" label="ж.к." />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6" class="pt-0">
                        <text-field
                            v-model="familyMember.familyMemberProfession"
                            required
                            label="Професия"
                            :rules="[$validator.maxLength(50)]"
                        />
                    </v-col>

                    <v-col cols="6" class="pt-0">
                        <text-field
                            v-model="familyMember.familyMemberPosition"
                            required
                            label="Длъжност"
                            :rules="[$validator.maxLength(50)]"
                        />
                    </v-col>
                </v-row>
                <alert :value="!isWorkplaceCityFilled" type="error">За месторабота не е попълнено полето град!</alert>
            </v-card-text>
        </template>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import CityPicker from '@/component/Picker/CityPicker.vue';
    import PidTypePicker from '@/component/Picker/PidTypePicker.vue';
    import { PidTypeCode } from '@/enum/Nomenclature/PidTypeCode';
    import { FamilyMemberDto } from '@/model/Exam/SickSheet/FamilyMemberDto';
    import { SickSheetDto } from '@/model/Exam/SickSheet/SickSheetDto';
    import { RhifAreaDto } from '@/model/Nomenclature/RhifAreaDto';
    import { RhifIdRhifAreaIdDto } from '@/model/Nomenclature/RhifIdRhifAreaIdDto';
    import { PatientWorkplaceDto } from '@/model/Patient/PatientWorkplaceDto';
    import { sickSheetService } from '@/service/Exam/SickSheetService';
    import { getRhifData } from '@/service/Nomenclature/CityService';
    import { rhifAreaService } from '@/service/Nomenclature/RhifAreaService';
    import { patientWorkplaceService } from '@/service/Patient/PatientWorkplaceService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { genderCache } from '@/store/Nomenclature/GenderCache';
    import { rhifCache } from '@/store/Nomenclature/RhifCache';
    import { formatters } from '@/util/Formatters';
    import { identifierUtil } from '@/util/IdentifierUtil';
    import PatientWorkplace from '@/view/Patient/PatientEdit/PatientWorkplaceView.vue';

    @Component({
        components: { CityPicker, PidTypePicker, PatientWorkplace }
    })
    export default class SickSheetPatientInfo extends Vue {
        @Prop()
        private choiceMaker!: boolean;

        @Prop({ type: Object })
        private familyMember!: FamilyMemberDto;

        @Prop()
        private sickSheet!: SickSheetDto;

        @Prop({ default: 'transparent' })
        private backgroundColor!: string;

        private get currentPatient() {
            return currentPatientCache.value;
        }

        private get genders() {
            return genderCache.items;
        }

        private workplacePosition(workplace: PatientWorkplaceDto) {
            return `${workplace.company} - ${workplace.position}`;
        }

        private get isWorkplaceCityFilled() {
            return (
                this.workplace.cityId ||
                (this.isFamilyMemberMode && this.familyMember.familyMemberWorkCityId !== null) ||
                this.workplace.foreignCity ||
                (this.isFamilyMemberMode && this.familyMember.familyMemberWorkForeignCity !== null)
            );
        }

        private workplace: PatientWorkplaceDto = new PatientWorkplaceDto();

        private isFamilyMemberMode: boolean = false;
        private workplaceDialogIsVisible: boolean = false;
        private rhifAreas: RhifAreaDto[] = [];
        private rhifIdAndRhifAreaId: RhifIdRhifAreaIdDto = new RhifIdRhifAreaIdDto();

        private choiceMakerDialogIsVisible: boolean = false;
        private patientWorkplaces: PatientWorkplaceDto[] = [];
        private isCloseClicked: boolean = false;
        private familyMembers: FamilyMemberDto[] = [];

        private familyMemberChange(item: FamilyMemberDto | null) {
            this.choiceMakerDialogIsVisible = false;
            this.$emit('family-member-change', item ?? new FamilyMemberDto());
        }

        private updateWorkplaces(workplaces: PatientWorkplaceDto[]) {
            this.patientWorkplaces = workplaces.filter((workplace) => workplace.isDeleted === false);
            this.onChangeWork(this.sickSheet.patientWorkplaceId);
            this.workplaceDialogIsVisible = false;
        }

        private closeDialog() {
            this.workplaceDialogIsVisible = false;
            this.isCloseClicked = true;
        }

        private get isEgnFMPidTypeCode() {
            return this.familyMember.familyMemberPidTypeCode === PidTypeCode.Egn;
        }

        private parseAgeGender() {
            this.familyMember.familyMemberGenderCode = identifierUtil.parseGender(
                this.familyMember?.familyMemberPidTypeCode,
                this.familyMember.familyMemberIdentifier ?? ''
            );

            const birthDate = identifierUtil.parseBirthDate(
                this.familyMember?.familyMemberPidTypeCode,
                this.familyMember.familyMemberIdentifier ?? ''
            );

            if (birthDate) {
                this.familyMember.familyMemberAge = this.calculateAge(birthDate);
            }
        }

        public calculateAge(birthDate: Date): number {
            const epochYear = 1970;
            return birthDate ? new Date(Date.now() - new Date(birthDate).valueOf()).getFullYear() - epochYear : 0;
        }

        @Watch('choiceMaker')
        private checkForChoiceMaker() {
            if (
                this.choiceMaker &&
                this.currentPatient?.choiceMaker &&
                !this.familyMember.familyMemberIdentifier &&
                !this.familyMember.familyMemberFirstName
            ) {
                this.choiceMakerDialogIsVisible = true;
            }
        }

        private populateWithChoiceMaker() {
            const choiceMaker = this.currentPatient?.choiceMaker;
            if (choiceMaker) {
                this.familyMember.familyMemberPidTypeCode = choiceMaker.personIdentification.pidTypeCode;
                this.familyMember.familyMemberIdentifier = choiceMaker.personIdentification.identifier;
                this.familyMember.familyMemberAge = choiceMaker.age;
                this.familyMember.familyMemberFirstName = choiceMaker.firstName;
                this.familyMember.familyMemberMiddleName = choiceMaker.middleName;
                this.familyMember.familyMemberLastName = choiceMaker.lastName;
                this.familyMember.familyMemberCityId = choiceMaker.address.cityId;
                this.familyMember.familyMemberStreet = choiceMaker.address.street;
                this.familyMember.familyMemberStreetNumber = choiceMaker.address.streetNumber;
                this.familyMember.familyMemberNeighborhood = choiceMaker.address.neighborhood;
                this.familyMember.familyMemberBlock = choiceMaker.address.block;
                this.familyMember.familyMemberEntrance = choiceMaker.address.entrance;
                this.familyMember.familyMemberFloor = choiceMaker.address.floor;
                this.familyMember.familyMemberApartment = choiceMaker.address.apartment;
                this.familyMember.familyMemberGenderCode = choiceMaker.personIdentification.genderCode;
            }
            this.choiceMakerDialogIsVisible = false;
        }

        private openWorkplaceDialog() {
            this.workplaceDialogIsVisible = true;
            this.isCloseClicked = false;
        }

        private get rhifs() {
            return rhifCache.items;
        }

        private async getRhifAreas(rhifId: number) {
            if (rhifId) {
                const response = await rhifAreaService.getRhifAreasByRhifId(rhifId);
                this.rhifAreas = response.data;
            } else {
                this.rhifAreas = [];
            }
        }

        private async getPatientWorkplaces() {
            this.patientWorkplaces = await patientWorkplaceService.getActivePatientWorkplaces(
                this.currentPatient.key.patientId
            );
        }

        @Watch('sickSheet.patientWorkplaceId')
        private onChangeWork(val: number) {
            if (val && this.patientWorkplaces) {
                const [chosenWorkplace] = this.patientWorkplaces.filter((work) => work.id === val);
                if (chosenWorkplace) {
                    this.workplace = chosenWorkplace;
                }
            }
        }

        @Watch('rhifIdAndRhifAreaId.rhifId', { immediate: true, deep: true })
        private async onRhifIdSelect(rhifId: number) {
            await this.getRhifAreas(rhifId);
        }

        @Watch('familyMember.familyMemberCityId')
        private async onFamilyMemberCityChange(cityId: number) {
            if (cityId) {
                this.rhifIdAndRhifAreaId = await getRhifData.getRhifDataByCityId(cityId);
            }
        }

        @Watch('choiceMaker', { immediate: true })
        private onStartCheckChoiceMaker(val: boolean) {
            this.isFamilyMemberMode = val;
        }

        @Watch('familyMember.familyMemberFirstName')
        private onFamilyMemberFirstNameChanged() {
            this.familyMember.familyMemberFirstName = formatters.capitalizeFirstLetter(
                this.familyMember.familyMemberFirstName
            );
        }

        @Watch('familyMember.familyMemberMiddleName')
        private onFamilyMemberMiddleNameChanged() {
            this.familyMember.familyMemberMiddleName = formatters.capitalizeFirstLetter(
                this.familyMember.familyMemberMiddleName
            );
        }

        @Watch('familyMember.familyMemberLastName')
        private onFamilyMemberLastNameChanged() {
            this.familyMember.familyMemberLastName = formatters.capitalizeFirstLetter(
                this.familyMember.familyMemberLastName
            );
        }

        @Watch('familyMember.familyMemberIdentifier')
        private onFamilyMemberIdentifierChanged() {
            if (
                this.familyMember?.familyMemberPidTypeCode === PidTypeCode.Egn &&
                this.familyMember.familyMemberIdentifier &&
                (!this.familyMember.familyMemberAge || !this.familyMember.familyMemberGenderCode)
            ) {
                this.parseAgeGender();
            }
        }

        private async getPatientFamilyMembers() {
            this.familyMembers = await sickSheetService.getPatientFamilyMembers(
                currentPatientCache.value.key.patientId
            );
        }

        private async mounted() {
            await this.getPatientWorkplaces();
            await this.getPatientFamilyMembers();
            if (this.sickSheet.patientWorkplaceId) {
                this.onChangeWork(this.sickSheet.patientWorkplaceId);
            }
        }
    }
</script>
