import { Type } from 'class-transformer';

import { dateUtil } from '@/util/DateUtil';

import { ProcessMemoryDto } from './ProcessMemoryDto';

export class EnvironmentDto {
    public localTimeString: string = '';
    public uploadTime: Date | null = null;
    public startTime: Date | null = null;
    public warmupSeconds: number = 0;
    public instanceTag: string = '';
    public scmUrl: string = '';
    public processId: number = 0;
    public upTimeHours: number = 0;
    public processorCount: number = 0;

    public cpuLoadP: number[] = [];
    public cpuOsLoadP: number[] = [];
    public poolSize: number[] = [];
    public workerThreads: number[] = [];
    public ioThreads: number[] = [];
    public runningThreads: number[] = [];
    public totalThreads: number[] = [];
    public pendingWork: number[] = [];
    public workPerSecond: number[] = [];
    public mbPerSecond: number[] = [];
    public committedMb: number[] = [];
    public heapMb: number[] = [];
    public fragmentedMb: number[] = [];
    public allocatedMb: number[] = [];
    public gcPerGeneration: number[][] = [];

    public gcPauseTimeP: number = 0;
    public promotedMb: number = 0;
    public usedMemoryP: number = 0;
    public hostTotalMb: number = 0;

    @Type(() => ProcessMemoryDto)
    public processMemory: ProcessMemoryDto = new ProcessMemoryDto();

    public sampleTime: Date = new Date();
    public loadedInMs: number = 0;

    public get processText() {
        return this.processId ? `${this.instanceTag} ${this.processId}` : '';
    }

    public get startupText() {
        return this.startTime
            ? `${dateUtil.formatBgDateTime(this.startTime)}${this.warmupSeconds ? ` за ${this.warmupSeconds} сек.` : ''}`
            : '';
    }

    public get gcWorkText() {
        return this.hostTotalMb ? `${this.gcPauseTimeP} % ; ${this.promotedMb} MB` : null;
    }

    public get hostText() {
        return this.processorCount
            ? `${this.processorCount} ; ${this.usedMemoryP} % от ${this.hostTotalMb} MB ; ${this.upTimeHours} ч.`
            : '';
    }

    public get loadTimeText() {
        return this.processId
            ? `${new Date(this.sampleTime).toLocaleTimeString('bg-BG')} за ${this.loadedInMs} ms`
            : '';
    }
}
