<template>
    <bar :responsive="true" :chart-data="chartData" :chart-options="options" :width="width" :height="height" />
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { ChartDataModel } from '@/model/Common/Chart/ChartDataModel';

    import { Bar } from './Charts';

    @Component({ components: { Bar } })
    export default class BarChart extends Vue {
        @Prop()
        private chartData!: ChartDataModel;

        @Prop({ default: 400 })
        private width!: number;

        @Prop({ default: 400 })
        private height!: number;

        @Prop({ default: '' })
        private title!: string;

        private get options() {
            return {
                plugins: {
                    datalabels: {
                        display: false
                    },
                    legend: {
                        display: false
                    },
                    title: {
                        display: Boolean(this.title),
                        text: this.title
                    }
                }
            };
        }
    }
</script>
