<template>
    <div>
        <v-card>
            <v-card-title>
                <v-row dense>
                    <v-col cols="12" md="2">Сървъри</v-col>
                    <v-col cols="12" md="7">
                        <v-row dense>
                            <v-col><small>API</small></v-col>
                            <v-col>
                                <small>
                                    <a
                                        class="text--secondary"
                                        :href="`${printApiBaseUrl}/Diagnostic/GetEnvironment`"
                                        target="_blank"
                                    >
                                        Печат
                                    </a>
                                </small>
                            </v-col>
                            <v-col>
                                <small>
                                    <a
                                        class="text--secondary"
                                        :href="`${identityServerBaseUrl}/Diagnostic/${isAuthenticated ? 'Session' : 'Server'}`"
                                        target="_blank"
                                    >
                                        IdentityServer
                                    </a>
                                </small>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-row>
                            <v-col><small>Пояснение</small></v-col>
                            <v-col cols="auto" class="pt-2">
                                <btn icon action="Refresh" @click="loadAllEnvAndDb">Обновяване на информацията</btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-title>

            <v-card-text>
                <v-row dense>
                    <v-col cols="12" md="2">Процес</v-col>
                    <v-col cols="12" md="7">
                        <v-row dense>
                            <v-col>
                                <a
                                    v-if="api.environment.scmUrl"
                                    class="text--secondary"
                                    :href="api.environment.scmUrl"
                                    target="_blank"
                                >
                                    {{ api.environment.processText }}
                                </a>
                                <template v-else>
                                    {{ api.environment.processText }}
                                </template>
                                <value-loading :value="api.environmentIsLoading"></value-loading>
                            </v-col>
                            <v-col>
                                <a
                                    v-if="print.environment.scmUrl"
                                    class="text--secondary"
                                    :href="print.environment.scmUrl"
                                    target="_blank"
                                >
                                    {{ print.environment.processText }}
                                </a>
                                <template v-else>
                                    {{ print.environment.processText }}
                                </template>
                                <value-loading :value="print.environmentIsLoading"></value-loading>
                            </v-col>
                            <v-col>
                                <!--
                                    От IdentityServer няма как да дойде sensitive информацията (като scmUrl),
                                    защото API-то му не е официално регистрирано като scope в самия IdentityServer,
                                    съответно не валидира access token-а и винаги се държи като анонимно извикано.
                                -->
                                <a
                                    v-if="idSrv.environment.scmUrl"
                                    class="text--secondary"
                                    :href="idSrv.environment.scmUrl"
                                    target="_blank"
                                >
                                    {{ idSrv.environment.processText }}
                                </a>
                                <template v-else>
                                    {{ idSrv.environment.processText }}
                                </template>
                                <value-loading :value="idSrv.environmentIsLoading"></value-loading>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="3">Instance ProcessId</v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12" md="2">Рестартиран на</v-col>
                    <v-col cols="12" md="7">
                        <v-row dense>
                            <v-col>{{ api.environment.startupText }}</v-col>
                            <v-col>{{ print.environment.startupText }}</v-col>
                            <v-col>{{ idSrv.environment.startupText }}</v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="3">Process.StartTime ; warmup</v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12" md="2">Процесор</v-col>
                    <v-col cols="12" md="7">
                        <v-row dense>
                            <v-col cols="12" md="4">
                                <line-chart :chart-data="api.cpuChartData" :height="chartHeight" />
                            </v-col>
                            <v-col cols="12" md="4">
                                <line-chart :chart-data="print.cpuChartData" :height="chartHeight" />
                            </v-col>
                            <v-col cols="12" md="4">
                                <line-chart :chart-data="idSrv.cpuChartData" :height="chartHeight" />
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="3">общо<br />OS</v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12" md="2">Нишки pool</v-col>
                    <v-col cols="12" md="7">
                        <v-row dense>
                            <v-col cols="12" md="4">
                                <line-chart :chart-data="api.poolChartData" :height="chartHeight * 1.2" />
                            </v-col>
                            <v-col cols="12" md="4">
                                <line-chart :chart-data="print.poolChartData" :height="chartHeight * 1.2" />
                            </v-col>
                            <v-col cols="12" md="4">
                                <line-chart :chart-data="idSrv.poolChartData" :height="chartHeight * 1.2" />
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="3">pooled<br />running, I/O</v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12" md="2">Нишки процес</v-col>
                    <v-col cols="12" md="7">
                        <v-row dense>
                            <v-col cols="12" md="4">
                                <line-chart :chart-data="api.threadChartData" :height="chartHeight" />
                            </v-col>
                            <v-col cols="12" md="4">
                                <line-chart :chart-data="print.threadChartData" :height="chartHeight" />
                            </v-col>
                            <v-col cols="12" md="4">
                                <line-chart :chart-data="idSrv.threadChartData" :height="chartHeight" />
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="3">общо<br />активни</v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12" md="2">Опашка</v-col>
                    <v-col cols="12" md="7">
                        <v-row dense>
                            <v-col cols="12" md="4">
                                <line-chart :chart-data="api.pendingChartData" :height="chartHeight * 0.8" />
                            </v-col>
                            <v-col cols="12" md="4">
                                <line-chart :chart-data="print.pendingChartData" :height="chartHeight * 0.8" />
                            </v-col>
                            <v-col cols="12" md="4">
                                <line-chart :chart-data="idSrv.pendingChartData" :height="chartHeight * 0.8" />
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="3">ThreadPool.Pending</v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12" md="2">Работа</v-col>
                    <v-col cols="12" md="7">
                        <v-row dense>
                            <v-col cols="12" md="4">
                                <line-chart :chart-data="api.workChartData" :height="chartHeight" />
                            </v-col>
                            <v-col cols="12" md="4">
                                <line-chart :chart-data="print.workChartData" :height="chartHeight" />
                            </v-col>
                            <v-col cols="12" md="4">
                                <line-chart :chart-data="idSrv.workChartData" :height="chartHeight" />
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="3">ThreadPool.Completed rate<br />allocations rate</v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12" md="2">GC памет</v-col>
                    <v-col cols="12" md="7">
                        <v-row dense>
                            <v-col cols="12" md="4">
                                <line-chart :chart-data="api.gcMemChartData" :height="chartHeight * 1.2" />
                            </v-col>
                            <v-col cols="12" md="4">
                                <line-chart :chart-data="print.gcMemChartData" :height="chartHeight * 1.2" />
                            </v-col>
                            <v-col cols="12" md="4">
                                <line-chart :chart-data="idSrv.gcMemChartData" :height="chartHeight * 1.2" />
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="3">committed, allocated,<br />heap, fragmented</v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12" md="2">Почиствания</v-col>
                    <v-col cols="12" md="7">
                        <v-row dense>
                            <v-col cols="12" md="4">
                                <line-chart :chart-data="api.gcPerGenChartData" :height="chartHeight" />
                            </v-col>
                            <v-col cols="12" md="4">
                                <line-chart :chart-data="print.gcPerGenChartData" :height="chartHeight" />
                            </v-col>
                            <v-col cols="12" md="4">
                                <line-chart :chart-data="idSrv.gcPerGenChartData" :height="chartHeight" />
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="3">gen 0, 1, 2</v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12" md="2"></v-col>
                    <v-col cols="12" md="7">
                        <v-row dense>
                            <v-col>{{ api.environment.gcWorkText }}</v-col>
                            <v-col>{{ print.environment.gcWorkText }}</v-col>
                            <v-col>{{ idSrv.environment.gcWorkText }}</v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="3">pause time ; last promoted</v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12" md="2">Памет приложна</v-col>
                    <v-col cols="12" md="7">
                        <v-row dense>
                            <v-col>{{ api.environment.processMemory.appMemoryText }}</v-col>
                            <v-col>{{ print.environment.processMemory.appMemoryText }}</v-col>
                            <v-col>{{ idSrv.environment.processMemory.appMemoryText }}</v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="3">working set (peak) / private</v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12" md="2">Памет системна</v-col>
                    <v-col cols="12" md="7">
                        <v-row dense>
                            <v-col>{{ api.environment.processMemory.systemMemoryText }}</v-col>
                            <v-col>{{ print.environment.processMemory.systemMemoryText }}</v-col>
                            <v-col>{{ idSrv.environment.processMemory.systemMemoryText }}</v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="3">ram + paged</v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12" md="2">Памет виртуална</v-col>
                    <v-col cols="12" md="7">
                        <v-row dense>
                            <v-col>{{ api.environment.processMemory.virtualMemoryText }}</v-col>
                            <v-col>{{ print.environment.processMemory.virtualMemoryText }}</v-col>
                            <v-col>{{ idSrv.environment.processMemory.virtualMemoryText }}</v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="3">current (peak)</v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12" md="2">Хост</v-col>
                    <v-col cols="12" md="7">
                        <v-row dense>
                            <v-col>{{ api.environment.hostText }}</v-col>
                            <v-col>{{ print.environment.hostText }}</v-col>
                            <v-col>{{ idSrv.environment.hostText }}</v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="3">ядра ; памет ; up time</v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12" md="2">App обновен на</v-col>
                    <v-col cols="12" md="7">
                        <v-row dense>
                            <v-col><date-label v-model="api.environment.uploadTime" show-time /></v-col>
                            <v-col><date-label v-model="print.environment.uploadTime" show-time /></v-col>
                            <v-col><date-label v-model="idSrv.environment.uploadTime" show-time /></v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="3">EntryAssembly.Location LastWriteTime</v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12" md="2">Изчислено в</v-col>
                    <v-col cols="12" md="7">
                        <v-row dense>
                            <v-col>{{ api.environment.loadTimeText }}</v-col>
                            <v-col>{{ print.environment.loadTimeText }}</v-col>
                            <v-col>{{ idSrv.environment.loadTimeText }}</v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="3">време за събиране на горната информация</v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12" md="2">Часовник app</v-col>
                    <v-col cols="12" md="7">
                        <v-row dense>
                            <v-col>{{ api.environment.localTimeString }}</v-col>
                            <v-col>{{ print.environment.localTimeString }}</v-col>
                            <v-col>{{ idSrv.environment.localTimeString }}</v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="3">сървърни формат на датата и часова зона</v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12" md="2">Часовник база</v-col>
                    <v-col cols="12" md="7">
                        <v-row dense>
                            <v-col>
                                <date-label v-model="api.dbLocalTime" show-time />
                                <value-loading :value="!api.dbLocalTime"></value-loading>
                            </v-col>
                            <v-col>
                                <date-label v-model="print.dbLocalTime" show-time />
                                <value-loading :value="!print.dbLocalTime"></value-loading>
                            </v-col>
                            <v-col>
                                <date-label v-model="idSrv.dbLocalTime" show-time />
                                <value-loading :value="!idSrv.dbLocalTime"></value-loading>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="3">linq DateTime.Now --> sql getdate()</v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12" md="2">Изходящ IP адрес</v-col>
                    <v-col cols="12" md="7">
                        <v-row dense>
                            <v-col>
                                {{ api.outboundIp }}
                                <value-loading :value="!api.outboundIp"></value-loading>
                            </v-col>
                            <v-col>
                                {{ print.outboundIp }}
                                <value-loading :value="!print.outboundIp"></value-loading>
                            </v-col>
                            <v-col>
                                {{ idSrv.outboundIp }}
                                <value-loading :value="!idSrv.outboundIp"></value-loading>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="3">
                        <a href="http://ipecho.net/plain" target="_blank">ipecho.net</a> (ще видите Вашето IP)
                    </v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12" md="2"></v-col>
                    <v-col cols="12" md="7">
                        <v-row dense>
                            <v-col></v-col>
                            <v-col>
                                <template v-if="printStorageStats.sizeInKb > 10240">
                                    {{ Math.round(printStorageStats.sizeInKb / 1024) }} MB
                                </template>
                                <template v-else>{{ printStorageStats.sizeInKb }} KB</template>
                                , {{ printStorageStats.count }} бр.
                            </v-col>
                            <v-col></v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="3">фрагменти за печат</v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <current-user-debug-info />

        <v-card>
            <v-card-title>SPA настройки</v-card-title>
            <v-card-text>
                <pre>{{ vueSpecificProcessEnv }}</pre>
            </v-card-text>
        </v-card>
    </div>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import LineChart from '@/component/Chart/LineChart.vue';
    import ValueLoading from '@/component/Common/ValueLoading.vue';
    import DateLabel from '@/component/Date/DateLabel.vue';
    import { config } from '@/Config';
    import { DiagnosticModel } from '@/model/DevTool/Diagnostic/DiagnosticModel';
    import { PrintStorageStatsDto } from '@/model/DevTool/Diagnostic/PrintStorageStatsDto';
    import { diagnosticService } from '@/service/DevTool/DiagnosticService';
    import { currentUser } from '@/store/User/CurrentUser';

    import CurrentUserDebugInfo from './CurrentUserDebugInfo.vue';

    const defaultChartHeight = 50;

    @Component({
        components: { LineChart, CurrentUserDebugInfo, DateLabel, ValueLoading }
    })
    export default class Diagnostic extends Vue {
        private api: DiagnosticModel = new DiagnosticModel();
        private print: DiagnosticModel = new DiagnosticModel();
        private idSrv: DiagnosticModel = new DiagnosticModel();
        private printStorageStats: PrintStorageStatsDto = new PrintStorageStatsDto();

        // Пуска паралелно 3 заявки към посочения сървър.
        private loadEnvAndDb(baseUrl: string, model: DiagnosticModel) {
            model.environmentIsLoading = true;
            diagnosticService.getEnvironment(baseUrl).then((result) => {
                model.environment = result;
                model.loadCharts();
                model.environmentIsLoading = false;
            });
            model.dbLocalTime = '';
            diagnosticService.getDbLocalTime(baseUrl).then((result) => {
                model.dbLocalTime = result;
            });
            model.outboundIp = '';
            diagnosticService.getOutboundIp(baseUrl).then((result) => {
                model.outboundIp = result;
            });
        }

        // Пуска паралелно по 3-4 заявки към 3 сървъра.
        private loadAllEnvAndDb() {
            this.loadEnvAndDb(config.mainApiBaseUrl, this.api);
            this.loadEnvAndDb(config.printApiBaseUrl, this.print);
            this.loadEnvAndDb(config.identityServerBaseUrl, this.idSrv);
            diagnosticService.getPrintStorageStats().then((value) => {
                this.printStorageStats = value;
            });
        }

        private mounted() {
            this.loadAllEnvAndDb();
        }

        private get isAuthenticated() {
            return currentUser.isAuthenticated;
        }

        private get printApiBaseUrl() {
            return config.printApiBaseUrl;
        }

        private get identityServerBaseUrl() {
            return config.identityServerBaseUrl;
        }

        private get vueSpecificProcessEnv() {
            return JSON.stringify(process.env, null, ' ');
        }

        private chartHeight: number = defaultChartHeight;
    }
</script>
