import { SpecialtyCode } from '@/enum/Nomenclature/SpecialtyCode';
import { ExamDocumentTypeCode } from '@/enum/VisitList/ExamDocumentTypeCode';
import { ExamDocumentTypeDto } from '@/model/Exam/ExamDocumentTypeDto';

const createExamDocumentTypes = () => [
    new ExamDocumentTypeDto('Имунизации', ExamDocumentTypeCode.Immunization),
    new ExamDocumentTypeDto('Е-Имунизации', ExamDocumentTypeCode.EImmunization),
    new ExamDocumentTypeDto('Бланка 3 - Напр. за консултация', ExamDocumentTypeCode.Referral3),
    new ExamDocumentTypeDto('Бланка 3а - Напр. за консултация и ВСД', ExamDocumentTypeCode.Referral3a),
    new ExamDocumentTypeDto('Бланка 4 - Направление за изследвания', ExamDocumentTypeCode.Referral4),
    new ExamDocumentTypeDto('Бланка 5 - рецепта НЗОК за 1 мес.', ExamDocumentTypeCode.PrescriptionV2Nhif5),
    new ExamDocumentTypeDto('Бланка 5a - рецепта НЗОК за 3 мес.', ExamDocumentTypeCode.PrescriptionV2Nhif5a),
    new ExamDocumentTypeDto('Бланка 5б - рецепта НЗОК военноинвалид', ExamDocumentTypeCode.PrescriptionV2Nhif5b),
    new ExamDocumentTypeDto('Бланка 5в - рецепта НЗОК ветеран', ExamDocumentTypeCode.PrescriptionV2Nhif5c),
    new ExamDocumentTypeDto('Бяла рецепта', ExamDocumentTypeCode.PrescriptionNhifWhite),
    new ExamDocumentTypeDto('Зелена рецепта', ExamDocumentTypeCode.PrescriptionNhifGreen),
    new ExamDocumentTypeDto('Жълта рецепта', ExamDocumentTypeCode.PrescriptionNhifYellow),
    new ExamDocumentTypeDto('Бланка 6 - Талон за ЛКК', ExamDocumentTypeCode.Referral6),
    new ExamDocumentTypeDto('Бланка 7 - Хоспитализация', ExamDocumentTypeCode.Referral7),
    new ExamDocumentTypeDto('Бланка 8 - Напр. за кл./амб. процедура', ExamDocumentTypeCode.Referral8),
    new ExamDocumentTypeDto('Бланка 8а - Напр. за кл./амб. процедура', ExamDocumentTypeCode.Referral8a),
    new ExamDocumentTypeDto('Медицинско направление/Искане за', ExamDocumentTypeCode.ReferralRequestFor),
    new ExamDocumentTypeDto('Манипулации', ExamDocumentTypeCode.Manipulation),
    new ExamDocumentTypeDto('Болничен лист', ExamDocumentTypeCode.SickSheet),
    new ExamDocumentTypeDto('Етапна епикриза', ExamDocumentTypeCode.StagedEpicrisis),
    new ExamDocumentTypeDto('Медицинско за работа', ExamDocumentTypeCode.CertificateWork),
    new ExamDocumentTypeDto('Медицинско за шофьор', ExamDocumentTypeCode.CertificateDrive),
    new ExamDocumentTypeDto('Е-Медицинско за шофьор', ExamDocumentTypeCode.ECertificateDrive),
    new ExamDocumentTypeDto('Медицинско за встъпване в брак', ExamDocumentTypeCode.CertificateMariage),
    new ExamDocumentTypeDto('Удостоверение за физикален преглед', ExamDocumentTypeCode.CertificatePhysicalExam),
    new ExamDocumentTypeDto('Медицинска бележка', ExamDocumentTypeCode.MedicalNote),
    new ExamDocumentTypeDto('Мед. направление за дисп. набл.', ExamDocumentTypeCode.ReferralDispObserv),
    new ExamDocumentTypeDto('Напр. за ТЕЛК', ExamDocumentTypeCode.ReferralTelk),
    new ExamDocumentTypeDto('Удостоверение', ExamDocumentTypeCode.CertificateSocialServices),
    new ExamDocumentTypeDto('Бързо известие', ExamDocumentTypeCode.QuickNotice),
    new ExamDocumentTypeDto('Информирано съгласие за лечение', ExamDocumentTypeCode.InformedConsentTreatment),
    new ExamDocumentTypeDto('Информирано съгласие за диагностика', ExamDocumentTypeCode.InformedConsentDiagnostics),
    new ExamDocumentTypeDto('Физиопроцедурна карта', ExamDocumentTypeCode.PhysiotherapyCard),
    new ExamDocumentTypeDto('Бланка за консултация при ТЕЛК', ExamDocumentTypeCode.ConsultationTelk),
    new ExamDocumentTypeDto(
        'Искане за хистопатологично изследване',
        ExamDocumentTypeCode.RequestForHistopathologicalTest
    ),
    new ExamDocumentTypeDto('Цитонамазка', ExamDocumentTypeCode.RequestForCytosmear),
    new ExamDocumentTypeDto('Искане за образно изследване', ExamDocumentTypeCode.RequestForImagingTest),
    new ExamDocumentTypeDto('Протокол за НЗОК лекарства(хартиен)', ExamDocumentTypeCode.DrugProtocol),
    new ExamDocumentTypeDto('Протокол за НЗОК лекарства', ExamDocumentTypeCode.NhifProtocol),
    new ExamDocumentTypeDto('Служебна бележка', ExamDocumentTypeCode.OfficialNotice),
    new ExamDocumentTypeDto('Лична здравно-профилактична карта', ExamDocumentTypeCode.ChildHealthCard),
    new ExamDocumentTypeDto('Антропометрия', ExamDocumentTypeCode.Anthropometry),
    new ExamDocumentTypeDto('Прикачени документи', ExamDocumentTypeCode.FileUpload),
    new ExamDocumentTypeDto('Резултати от лечение', ExamDocumentTypeCode.Results),
    new ExamDocumentTypeDto('Решение на ЛКК', ExamDocumentTypeCode.LkkDecision)
];

const createNoExamDocumentTypes = () => [
    new ExamDocumentTypeDto('Болничен лист', ExamDocumentTypeCode.SickSheet),
    new ExamDocumentTypeDto('Решение на ЛКК', ExamDocumentTypeCode.LkkDecision),
    new ExamDocumentTypeDto('Етапна епикриза', ExamDocumentTypeCode.StagedEpicrisis),
    new ExamDocumentTypeDto('Прикачени документи', ExamDocumentTypeCode.FileUpload),
    // Когато няма преглед, се поддържа само ръчното добавяне на документи, без резултатите от е-направление.
    new ExamDocumentTypeDto('Резултати от лечение', ExamDocumentTypeCode.Results)
];

// Менюто е направено така че да ходи до /VisitDocumentMenu/GetExamDocumentCount
// За да се генерира и покаже елемент от зададения enum, трябва да го има като елемент в GetExamDocumentCount() (VisitDocumentMenuController)
const VisitMenuSpecialtyAdditions: Record<SpecialtyCode, ExamDocumentTypeDto[]> = {
    Gp: [new ExamDocumentTypeDto('Карта за оценка на рискови фактори', ExamDocumentTypeCode.RiskAssessmentCard)],
    Gynecology: [],
    Rehabilitation: [],
    Anesthesiology: [],
    Pediatrics: []
};

// За всяка специалност се задават елементи от ExamDocumentTypeDto, които да не се генерират в менюто за посещението.
// Пример: Gp: [ExamDocumentTypeDto.Referral8a, ExamDocumentTypeDto.ConsultationTelk, ExamDocumentTypeDto.NhifProtocol]
// Ако масивът за съответната специалност е празен, ще заредят всички елементи от ExamDocumentTypeDto.
const VisitMenuSpecialtyExclusions: Record<SpecialtyCode, ExamDocumentTypeCode[]> = {
    Gp: [
        ExamDocumentTypeCode.Referral8a,
        ExamDocumentTypeCode.InformedConsentTreatment,
        ExamDocumentTypeCode.InformedConsentDiagnostics,
        ExamDocumentTypeCode.PhysiotherapyCard,
        ExamDocumentTypeCode.ConsultationTelk,
        ExamDocumentTypeCode.RequestForHistopathologicalTest,
        ExamDocumentTypeCode.DrugProtocol,
        ExamDocumentTypeCode.NhifProtocol,
        ExamDocumentTypeCode.LkkDecision
    ],
    Gynecology: [],
    Rehabilitation: [],
    Anesthesiology: [],
    Pediatrics: []
};

export {
    createExamDocumentTypes,
    createNoExamDocumentTypes,
    VisitMenuSpecialtyAdditions,
    VisitMenuSpecialtyExclusions
};
