import { ResultReferralPerformerDto } from './ResultReferralPerformerDto';
import { ResultReferralProcedureDto } from './ResultReferralProcedureDto';

export class ResultReferral4Dto {
    public resultNrn: string = '';
    public performerInfo: ResultReferralPerformerDto = new ResultReferralPerformerDto();
    public openDate: Date | null = null;
    public procedures: ResultReferralProcedureDto[] = [];
    public documentId: number = 0;
    public status: string = '';
    public psaRatio: number | null = null;
}
