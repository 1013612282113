// Тук се регистрират всички елементи, нужни за всички видове chart-ове, използвани в проекта.
// Ако всеки вид chart регистрира само нужните за него елементи, се получават аномалии.
// Пример: Екран с bar chart и pie chart гърми в конзолата, bar chart-ът излиза с грешен размер, pie chart не се появява.
// Кръпка: В bar chart-а се import-ва и регистрира DataLabels, въпреки че те са нужи само за pie chart.
// Като страничен ефект се пръкват излишни labels, които трябва да скрием с настройка на datalabels plugin-а за bar chart.
// Всичко това се опростява, като предварително се регистрират всички елементи за всички използвани видове chart-ове.
import {
    // Само за pie chart.
    ArcElement,
    // Само за bar chart.
    BarElement,
    // Без CategoryScale и LinearScale не се показва нищо.
    CategoryScale,
    Chart as ChartJS,
    // Само за line chart. Без това не работи property-то options.fill.
    Filler,
    // За да се скрие легендата чрез настройки на legend plugin-а.
    Legend,
    // Без CategoryScale и LinearScale не се показва нищо.
    LinearScale,
    // Само за line chart.
    LineElement,
    // Само за line chart.
    PointElement,
    // За да се покаже заглавие чрез настройки на title plugin-а.
    Title,
    // За да излиза tooltip при hover върху данните.
    Tooltip
} from 'chart.js';
// Показва стойностите върху самия chart. Позволява на pie chart да показва проценти върху кръгчето.
// Трябва обаче да се изключи за bar и line chart, за да НЕ излизат числа върху стълбовете и линиите.
import DataLabels from 'chartjs-plugin-datalabels';
// Ре-експорт на всички видове chart-ове, използвани в проекта.
import { Bar, Line, Pie } from 'vue-chartjs/legacy';

ChartJS.register(
    ArcElement,
    BarElement,
    CategoryScale,
    DataLabels,
    Filler,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip
);

export { Bar, Line, Pie };
