// Вместо httpService, този модул използва директно axios, защото:
// - извиква action-и от 3 различни системи с абсолютни адреси, поради което в конфигурацията на axois няма baseURL.
// - exception-ите не трябва да се показват в модален диалог, защото се изписват на екрана вместо резултат.
import axios from 'axios';
//import axios from 'axios';
import { plainToInstance } from 'class-transformer';

import { config } from '@/Config';
import { EnvironmentDto } from '@/model/DevTool/Diagnostic/EnvironmentDto';
import { PrintStorageStatsDto } from '@/model/DevTool/Diagnostic/PrintStorageStatsDto';
import { currentUser } from '@/store/User/CurrentUser';

const axiosInstance = axios.create();

// Преди изпращане на заявката access token-ът, ако има такъв, се добавя в хедърите.
axiosInstance.interceptors.request.use((requestConfig) => {
    const { user } = currentUser;
    const accessToken = user?.access_token;
    if (requestConfig.headers && accessToken) {
        requestConfig.headers.Authorization = `Bearer ${accessToken}`;
    }
    return requestConfig;
});

const formatUrl = (baseUrl: string, action: string) => (baseUrl ? `${baseUrl}/${action}` : action);

export const diagnosticService = {
    async getEnvironment(baseUrl: string): Promise<EnvironmentDto> {
        try {
            const response = await axiosInstance.get<EnvironmentDto>(
                formatUrl(baseUrl, 'Diagnostic/GetEnvironment?sampleCount=120')
            );
            return plainToInstance(EnvironmentDto, response.data);
        } catch (error: unknown) {
            const result = new EnvironmentDto();
            result.localTimeString = (error as Error).message;
            return result;
        }
    },

    async getDbLocalTime(baseUrl: string): Promise<Date | string> {
        try {
            const response = await axiosInstance.get<Date>(formatUrl(baseUrl, 'Diagnostic/GetDbLocalTime'));
            return response.data;
        } catch (error: unknown) {
            return (error as Error).message;
        }
    },

    async getOutboundIp(baseUrl: string): Promise<string> {
        try {
            const response = await axiosInstance.get<string>(formatUrl(baseUrl, 'Diagnostic/GetOutboundIp'));
            return response.data;
        } catch (error: unknown) {
            return (error as Error).message;
        }
    },

    async getPrintStorageStats(): Promise<PrintStorageStatsDto> {
        try {
            const response = await axiosInstance.get<PrintStorageStatsDto>(
                `${config.printApiBaseUrl}/Diagnostic/GetStorageStats`
            );
            return plainToInstance(PrintStorageStatsDto, response.data);
        } catch (error: unknown) {
            return new PrintStorageStatsDto();
        }
    }
};
