<template>
    <div>
        <v-card v-if="securityCodeHasConfirmed">
            <v-card-title>Финансов отчет-обобщен</v-card-title>
            <v-card-text>
                <report-date-picker v-model="command" />
                <v-row>
                    <v-col md="3" cols="6">
                        <dropdown
                            v-model="command.createdByEmployeeSeqNumber"
                            :items="employees"
                            item-value="employeeSeqNumber"
                            item-text="employeeTitle"
                            label="Обработил"
                            clearable
                        ></dropdown>
                    </v-col>
                    <v-col md="3" cols="6">
                        <dropdown
                            v-model="command.creditToEmployeeSeqNumber"
                            :items="employees"
                            item-value="employeeSeqNumber"
                            item-text="employeeTitle"
                            label="В полза на"
                            clearable
                        ></dropdown>
                    </v-col>
                    <v-col md="3" cols="6">
                        <dropdown
                            v-model="command.paymentMethodCode"
                            label="Начин на плащане"
                            :items="paymentMethods"
                            item-value="code"
                            clearable
                        ></dropdown>
                    </v-col>
                    <v-col md="3" cols="6">
                        <dropdown
                            v-model="command.tariffSeqNumber"
                            label="Тарифа"
                            :items="tariffs"
                            item-value="seqNumber"
                            clearable
                        ></dropdown>
                    </v-col>
                    <v-col md="3" cols="6">
                        <dropdown
                            v-model="command.isPaid"
                            label="Статус на плащане"
                            :items="paidTypes"
                            item-value="value"
                        ></dropdown>
                    </v-col>
                    <v-col md="3" cols="6">
                        <dropdown
                            v-model="command.groupBy"
                            label="Групиране по"
                            :items="groupByTypes"
                            item-value="code"
                            @input="getReport"
                        ></dropdown>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-col cols="2">
                    <btn action="List" block @click="getReport">Генериране</btn>
                </v-col>
                <v-col cols="2">
                    <btn action="Export" block @click="exportExcel" />
                </v-col>
            </v-card-actions>
            <data-table
                :headers="summarizedTableHeaders"
                :items="summarizedFinancialReport"
                :group-by="command.groupBy"
                :custom-sort="customSort"
            >
                <template #groupSummary="{ items, group }">
                    <strong>Междинно за {{ group }}</strong
                    >: Общ брой: {{ items.length }}, Обща сума:
                    {{ items.reduce((acc, item) => acc + item.summaryPrice, 0).toFixed(2) }}
                </template>
            </data-table>
            <v-row class="mt-2">
                <v-spacer />
                <v-col cols="9" class="text-right"> Сума (общо) </v-col>
                <v-col cols="3"> {{ totalPrice }} лв </v-col>
            </v-row>
        </v-card>
        <FinancialStatementsProtection
            v-else
            v-model="securityCodeHasConfirmed"
            :doctor-employee-seq-number="currentDoctorEmployeeSeqNumber"
            :practice-id="practiceId"
        />
        <v-card v-if="showChart && securityCodeHasConfirmed">
            <bar-chart
                :chart-data="chartData"
                :height="100"
                :title="`Финансов отчет-обобщен по ${command.groupBy === 'createdOn' ? 'дата' : 'месец'}`"
            />
        </v-card>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import BarChart from '@/component/Chart/BarChart.vue';
    import ReportDatePicker from '@/component/Date/ReportDatePicker.vue';
    import FinancialStatementsProtection from '@/component/PinCode/PinCode.vue';
    import { ChartDataModel } from '@/model/Common/Chart/ChartDataModel';
    import { ChartDatasetModel } from '@/model/Common/Chart/ChartDatasetModel';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { EmployeeTitleDto } from '@/model/Employee/EmployeeTitleDto';
    import { PaymentMethodDto } from '@/model/Nomenclature/PaymentMethodDto';
    import { TariffDto } from '@/model/Practice/Service/TariffDto';
    import { IsPaidType, isPaidTypes } from '@/model/Query/Reception/IsPaidType';
    import { ServiceOrderFinancialReportCommand } from '@/model/Query/Reception/ServiceOrderFinancialReportCommand';
    import { ServiceOrderGroupType, serviceOrderGroupTypes } from '@/model/Query/Reception/ServiceOrderGroupType';
    import { ServiceOrderSummarizedFinancialReportDto } from '@/model/Query/Reception/ServiceOrderSummarizedFinancialReportDto';
    import { employeeService } from '@/service/Employee/EmployeeService';
    import { paymentMethodService } from '@/service/Nomenclature/PaymentMethodService';
    import { tariffService } from '@/service/Practice/Service/TariffService';
    import { serviceOrderSummarizedReportService } from '@/service/Query/Reception/ServiceOrderSummarizedReportService';
    import { loadingState } from '@/store/LoadingState';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { arrayUtil } from '@/util/ArrayUtil';

    @Component({
        components: { BarChart, ReportDatePicker, FinancialStatementsProtection }
    })
    export default class ServiceOrderSummarizedReport extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        private command: ServiceOrderFinancialReportCommand = new ServiceOrderFinancialReportCommand();
        private employees: EmployeeTitleDto[] = [];
        private paymentMethods: PaymentMethodDto[] = [];
        private tariffs: TariffDto[] = [];
        private paidTypes: IsPaidType[] = isPaidTypes;
        private groupByTypes: ServiceOrderGroupType[] = serviceOrderGroupTypes;
        private summarizedFinancialReport: ServiceOrderSummarizedFinancialReportDto[] = [];
        private chartData: ChartDataModel = new ChartDataModel();
        private securityCodeHasConfirmed: boolean = false;

        private get summarizedTableHeaders(): IDataTableHeader[] {
            return [
                { text: 'Услуга', value: 'serviceName' },
                { text: 'Брой', value: 'count' },
                { text: 'Сума', value: 'summaryPrice' }
            ];
        }

        private get showChart() {
            return this.command.groupBy === 'createdOn' || this.command.groupBy === 'createdOnMonth';
        }

        private get currentDoctorEmployeeSeqNumber() {
            return userContextCache.currentDoctorEmployeeSeqNumber;
        }

        private async getReport() {
            await this.getSummarizedFinancialReport();
            this.getBarChartData();
        }

        private get totalPrice() {
            const fractionDigit = 2;
            return this.summarizedFinancialReport
                .reduce((accumulator: number, serviceOrder: ServiceOrderSummarizedFinancialReportDto) => {
                    const servicePrice = parseFloat(serviceOrder.summaryPrice?.toString() ?? '');
                    return accumulator + (isNaN(servicePrice) ? 0 : servicePrice);
                }, 0)
                .toFixed(fractionDigit);
        }

        private async getEmployeeTitles() {
            this.employees = await employeeService.getEmployeeTitles(this.practiceId);
        }

        private async getPaymentMethod() {
            this.paymentMethods = await paymentMethodService.getPaymentMethods();
        }

        private async getTariffs() {
            this.tariffs = await tariffService.getPracticeTariffs(this.practiceId);
        }

        private async getSummarizedFinancialReport() {
            this.$loading.show();
            try {
                this.summarizedFinancialReport = [];
                this.fillPractice();
                this.summarizedFinancialReport = await serviceOrderSummarizedReportService.getSummarizedFinancialReport(
                    this.command
                );
            } finally {
                this.$loading.hide();
            }
        }

        private async exportExcel() {
            loadingState.show();
            try {
                this.fillPractice();
                await serviceOrderSummarizedReportService.exportDataSummarizedReport(this.command);
            } finally {
                loadingState.hide();
            }
        }

        private fillPractice() {
            this.command.practiceId = this.practiceId;
        }

        private getBarChartData() {
            if (this.showChart) {
                let labels: string[] = [];
                const dataset = new ChartDatasetModel();
                let groupToMap: Map<string, ServiceOrderSummarizedFinancialReportDto[]> = new Map<
                    string,
                    ServiceOrderSummarizedFinancialReportDto[]
                >();

                if (this.command.groupBy === 'createdOn') {
                    groupToMap = arrayUtil.groupByToMap(this.summarizedFinancialReport, (item) => item.createdOn);
                    labels = Array.from(groupToMap.keys());
                } else {
                    groupToMap = arrayUtil.groupByToMap(this.summarizedFinancialReport, (item) => item.createdOnMonth);
                    labels = Array.from(groupToMap.keys());
                }

                for (const [, value] of groupToMap) {
                    dataset.data.push(value.reduce((accumulator, object) => accumulator + object.summaryPrice, 0));
                }

                dataset.borderWidth = 1;
                dataset.backgroundColor = ['rgba(76, 213, 68, 0.2)'];
                dataset.borderColor = ['rgb(139, 213, 68)'];
                this.chartData.labels = labels;
                this.chartData.datasets = [dataset];
            }
        }

        private async load() {
            this.$loading.show();
            try {
                this.command.fillCurrentMonth();
                this.removeNoneGroupOption();
                await this.getEmployeeTitles();
                await this.getPaymentMethod();
                await this.getTariffs();
                await this.getReport();
            } finally {
                this.$loading.hide();
            }
        }

        private removeNoneGroupOption() {
            const itemToRemove = this.groupByTypes.find((item) => item.code === 'none');
            if (itemToRemove) {
                const index = this.groupByTypes.indexOf(itemToRemove);
                this.groupByTypes.splice(index, 1);
                const groupBy = this.groupByTypes.find((item) => item.code === 'serviceName');
                this.command.groupBy = groupBy?.code ?? '';
            }
        }

        customSort(items: ServiceOrderSummarizedFinancialReportDto[]) {
            if (items?.length > 0) {
                if (this.command.groupBy === 'createdOn') {
                    items.sort(
                        (item1, item2) => new Date(item1.createdOn).getTime() - new Date(item2.createdOn).getTime()
                    );
                }
            }
            return items;
        }

        private async mounted() {
            await this.load();
        }
    }
</script>
