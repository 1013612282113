import { SpecialtyCode } from '@/enum/Nomenclature/SpecialtyCode';
import { userContextCache } from '@/store/User/UserContextCache';

import { MenuItemModel } from './MenuItemModel';

export const createRegisterMenuItems = () => {
    const { current } = userContextCache;
    if (!current) {
        return [];
    }
    const { practiceId, employeeSeqNumber, doctorEmployeeId, specialtyCode } = current;
    const menuItems = [
        new MenuItemModel('Търсене', '', ''),
        new MenuItemModel('Търсене на пациенти', 'mdi-account-search-outline', `/Register/PatientSearch/${practiceId}`),
        new MenuItemModel('Регистри', '', ''),
        new MenuItemModel(
            'Диспансерен регистър',
            'mdi-human-cane',
            `/Register/DispensaryRegister/${practiceId}/${employeeSeqNumber}`
        ),
        new MenuItemModel('Бременности', 'mdi-human-pregnant', `/Register/PregnancyRegister/${doctorEmployeeId}`)
    ];

    if (specialtyCode === SpecialtyCode.Gp || specialtyCode === SpecialtyCode.Pediatrics) {
        menuItems.push(
            new MenuItemModel(
                'Детско здравеопазване',
                'mdi-baby-carriage',
                `/Register/ChildrenHealthRegister/${doctorEmployeeId}`
            ),
            new MenuItemModel('Рискови групи', 'mdi-account-alert', '/Register/RiskGroupRegister/')
        );
    }
    return menuItems;
};
