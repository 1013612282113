<template>
    <pie :responsive="true" :chart-data="chartData" :chart-options="options" />
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { ChartDataModel } from '@/model/Common/Chart/ChartDataModel';

    import { Pie } from './Charts';

    @Component({ components: { Pie } })
    export default class PieChart extends Vue {
        @Prop()
        private chartData!: ChartDataModel;

        @Prop({ default: '' })
        private title!: string;

        private get options() {
            return {
                plugins: {
                    datalabels: {
                        formatter(value: number) {
                            return `${value}%`;
                        },
                        display: 'auto',
                        color: 'white'
                    },
                    legend: {
                        position: 'top'
                    },
                    title: {
                        display: Boolean(this.title),
                        text: this.title
                    }
                }
            };
        }
    }
</script>
