// Този модел може да бъде допълван с още пропъртита в зависимост от нуждите на бар чарта
// Повече информация моде да се намери тук => https://www.chartjs.org/docs/3.9.1/charts/bar.html
export class ChartDatasetModel {
    public data: number[] = [];
    public backgroundColor?: string | string[];
    public borderColor?: string | string[];
    public borderWidth?: number;
    public label?: string;
    public fill?: boolean | string;
    public pointRadius?: number;
    public tension?: number;
    public yAxisID?: string;
}
