<template>
    <line-chart-generator :chart-data="chartData" :chart-options="options" :height="height" />
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { ChartDataModel } from '@/model/Common/Chart/ChartDataModel';
    import { userSettingsState } from '@/store/User/UserSettingsState';

    import { Line as LineChartGenerator } from './Charts';

    @Component({ components: { LineChartGenerator } })
    export default class LineChart extends Vue {
        @Prop({ required: true })
        private chartData!: ChartDataModel;

        @Prop({ default: 50 })
        private height!: number;

        private get scalesOptions() {
            return {
                // Скрива хоризонталната ос.
                xAxis: {
                    display: false
                },
                // Поддържаме 2 вертикални оси - лява и дясна. Всички оси по подразбиране са видими, затова дясната се скрива по-долу.
                yAxis: {
                    //display: false,
                    // По подразбиране минималната и максималната стойност за вертикалната ос се взимат от данните.
                    // Може обаче да се заковат, например на 0 и 100, когато става дума за проценти.
                    //min: 0
                    //max: 100
                },
                yRight: {
                    // Дясната вертикална ос се скрива, освен ако няма поне един dataset, който да я ползва.
                    display: this.chartData.datasets.some((ds) => ds.yAxisID === 'yRight'),
                    // Чрез 'as const' се заобикаля ts грешката, че string не е съвместимо с тип 'left' | 'right | ...
                    position: 'right' as const,
                    // На дясната ос излизаха дробни числа (0.9, 1.0), не е ясно защо само на нея.
                    ticks: {
                        precision: 0
                    }
                }
            };
        }

        private get options() {
            return {
                interaction: {
                    // Показва tooltip-а без да е нужно да се прицелим точно в точките от линията.
                    // https://www.chartjs.org/docs/3.9.1/configuration/interactions.html
                    intersect: false
                },
                // Следното прави Vue 3 варианта responsive, но фиксира размерите (в случая height=50). Не е ясно как е по-добре.
                // Vue 2 вариантът си е responsive и без това, но е добавено и тук за еднаквост в Vue 3 варианта.
                // https://www.chartjs.org/docs/latest/configuration/responsive.html#important-note
                maintainAspectRatio: false,
                scales: this.scalesOptions,

                // Това са property-та от ChartDatasetModel, но в случая са указани на ниво chart за всички dataset-и.
                // Бледо потъмняване на пощта под линията (или изсветляване на тъмна тема).
                backgroundColor: userSettingsState.userSettings.ui.isDarkTheme
                    ? 'rgba(255, 255, 255, 0.05)'
                    : 'rgba(0, 0, 0, 0.05)',
                // Тънка линия (по подразбиране е 3).
                borderWidth: 1,
                // Включва запълването под линията.
                fill: true,
                // Скрива точките.
                pointRadius: 0,
                // Леко заобля линията.
                tension: 0.2,

                plugins: {
                    datalabels: {
                        display: false
                    },
                    legend: {
                        display: false
                    }
                }
            };
        }
    }
</script>
