<template>
    <v-container fluid>
        <!-- В повечето случаи се изписва цялото лого+надпис "Хипократ". При размер xs излиза само лого "Х". -->
        <v-row>
            <v-col>
                <v-row>
                    <v-col class="hidden-xs-only">
                        <v-img
                            src="@/asset/Logo/Nadpis.png"
                            alt="Хипократ"
                            :max-width="advertisementImg ? '300px' : '650px'"
                        />
                    </v-col>
                    <v-col class="hidden-sm-and-up">
                        <img class="mx-auto" src="@/asset/Logo/Logo.png" alt="Хипократ" max-width="175px" />
                    </v-col>
                </v-row>
                <v-row v-if="advertisementImg" dense>
                    <h2>Здравно-информационна система за ОПЛ и специалисти в извънболнични лечебни заведения</h2>
                </v-row>
                <v-row v-else dense>
                    <h1>Здравно-информационна система за ОПЛ и специалисти в извънболнични лечебни заведения</h1>
                </v-row>
                <v-row v-if="advertisementImg" justify="center" align="center">
                    <v-col sm="6">
                        <v-img max-width="750px" :src="advertisementImg"></v-img>
                    </v-col>
                </v-row>
            </v-col>
            <v-divider v-if="currentUserContext" vertical></v-divider>
            <v-col v-if="currentUserContext" cols="2">
                <bar-chart
                    v-if="examsBarChartData.labels.length > 0"
                    :chart-data="examsBarChartData"
                    title="Брой прегледи за последната година"
                />
                <pie-chart
                    v-if="referral3ServerData && referral3ServerData.sumValue"
                    :chart-data="referral3PieChartData"
                    :title="`Регулативен стандарт - общ брой използвани бл.МЗ - НЗОК №3/3а (${referral3ServerData.sumValue})`"
                />
                <pie-chart
                    v-if="referral4ServerData && referral4ServerData.sumValue"
                    :chart-data="referral4PieChartData"
                    :title="`Регулативен стандарт - използвана обща сума бл.МЗ - НЗОК №4 (${referral4ServerData.sumValue}лв.)`"
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts">
    import { Component, Vue, Watch } from 'vue-property-decorator';

    import BarChart from '@/component/Chart/BarChart.vue';
    import PieChart from '@/component/Chart/PieChart.vue';
    import { AdvertisementTypeCode } from '@/enum/AdvertisementTypeCode';
    import { ChartDataModel } from '@/model/Common/Chart/ChartDataModel';
    import { ChartDatasetModel } from '@/model/Common/Chart/ChartDatasetModel';
    import { PieChartDto } from '@/model/Dashboard/PieChartDto';
    import { advertisementService } from '@/service/AdvertisementService';
    import { dashboardService } from '@/service/Dashboard/DashboardService';
    import { userContextCache } from '@/store/User/UserContextCache';

    @Component({ components: { BarChart, PieChart } })
    export default class Home extends Vue {
        private examsBarChartData: ChartDataModel = new ChartDataModel();
        private referral3ServerData: PieChartDto = new PieChartDto();
        private referral3PieChartData: ChartDataModel = new ChartDataModel();
        private referral4ServerData: PieChartDto = new PieChartDto();
        private referral4PieChartData: ChartDataModel = new ChartDataModel();
        private advertisementImg: string | null = null;

        private get currentUserContext() {
            return userContextCache.current;
        }

        @Watch('currentUserContext', { immediate: true })
        private onCurrentUserContextChanged() {
            if (userContextCache.current) {
                this.loadBarChartData();
                this.loadPieChartData(true);
                this.loadPieChartData(false);
                this.loadHomeAdvertisement();
            }
        }

        private async loadBarChartData() {
            const { current } = userContextCache;
            if (!current) {
                return;
            }
            const { data } = await dashboardService.getBarChartExamData(current.practiceId);
            this.examsBarChartData.labels = Array.from({ length: 12 }, (element, index) =>
                new Date(0, index + data.startingMonth, 0).toLocaleDateString('bg-BG', {
                    month: 'long'
                })
            );
            const dataset = new ChartDatasetModel();
            dataset.data = data.monthExamCounts;
            dataset.backgroundColor = [
                'rgba(255, 99, 132, 0.2)',
                'rgba(255, 159, 64, 0.2)',
                'rgba(255, 205, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(201, 203, 207, 0.2)',
                'rgba(100, 68, 213, 0.2)',
                'rgba(151, 68, 213, 0.2)',
                'rgba(213, 68, 208, 0.2)',
                'rgba(139, 213, 68, 0.2)',
                'rgba(76, 213, 68, 0.2)'
            ];
            dataset.borderColor = [
                'rgb(255, 99, 132)',
                'rgb(255, 159, 64)',
                'rgb(255, 205, 86)',
                'rgb(75, 192, 192)',
                'rgb(54, 162, 235)',
                'rgb(153, 102, 255)',
                'rgb(201, 203, 207)',
                'rgb(100, 68, 213)',
                'rgb(151, 68, 213)',
                'rgb(213, 68, 208)',
                'rgb(139, 213, 68)',
                'rgb(76, 213, 68)'
            ];
            dataset.borderWidth = 1;
            this.examsBarChartData.datasets = [dataset];
        }

        private async loadPieChartData(isForReferral3: boolean) {
            const { current } = userContextCache;
            if (!current) {
                return;
            }
            const dataset = new ChartDatasetModel();
            dataset.borderWidth = 0;
            dataset.backgroundColor = ['rgb(255, 99, 132)', 'rgb(54, 162, 235)'];
            if (isForReferral3) {
                this.referral3ServerData = (
                    await dashboardService.getReferral3PieChartData(current.practiceId, current.employeeSeqNumber)
                ).data;
                this.referral3PieChartData.labels = this.referral3ServerData.labels;
                dataset.data = this.referral3ServerData.pieData;
                this.referral3PieChartData.datasets = [dataset];
            } else {
                this.referral4ServerData = (
                    await dashboardService.getReferral4PieChartData(current.practiceId, current.employeeSeqNumber)
                ).data;
                this.referral4PieChartData.labels = this.referral4ServerData.labels;
                dataset.data = this.referral4ServerData.pieData;
                this.referral4PieChartData.datasets = [dataset];
            }
        }

        private async loadHomeAdvertisement() {
            const { current } = userContextCache;
            if (current) {
                const advertisements = await advertisementService.getValidAdvertisements({
                    practiceId: current.practiceId,
                    advertisementTypeCode: AdvertisementTypeCode.Home,
                    icdCode: null
                });
                const [homeScreenAdvertisement] = advertisements;

                if (homeScreenAdvertisement) {
                    this.advertisementImg = `data:image/png;base64,${homeScreenAdvertisement.fileContent}`;
                } else {
                    this.advertisementImg = null;
                }
            }
        }
    }
</script>
