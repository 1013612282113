import { ChartDataModel } from '@/model/Common/Chart/ChartDataModel';
import { ChartDatasetModel } from '@/model/Common/Chart/ChartDatasetModel';
import { userSettingsState } from '@/store/User/UserSettingsState';

import { EnvironmentDto } from './EnvironmentDto';

const lineColors = [
    ['dimgray', 'lightgray'],
    ['darkred', 'pink'],
    ['darkgreen', 'lightgreen'],
    ['blue', 'lightblue']
];

const initChartData = (chartData: ChartDataModel, labels: string[], data: number[][]) => {
    const datasets: ChartDatasetModel[] = Array(labels.length);
    for (let index = 0; index < labels.length; ++index) {
        const dataset = new ChartDatasetModel();
        dataset.label = labels[index];
        dataset.data = data[index];
        // Примерни данни.
        // eslint-disable-next-line
        //dataset.data = index == 0 ? [65, 59, 80, 81, 56, 55, 40, 81, 56, 55, 40, 60] : [25, 39, 5, 31, 40, 25, 10, 31, 16, 35, 2, 22];
        dataset.borderColor = lineColors[index][userSettingsState.userSettings.ui.isDarkTheme ? 1 : 0];
        //dataset.yAxisID = 'wtf';
        datasets[index] = dataset;
    }

    const emptyLabels = datasets.length ? Array(datasets[0].data.length).fill('') : [];
    chartData.labels = emptyLabels;
    chartData.datasets = datasets;
};

export class DiagnosticModel {
    public environmentIsLoading: boolean = false;
    public environment: EnvironmentDto = new EnvironmentDto();
    public cpuChartData: ChartDataModel = new ChartDataModel();
    public poolChartData: ChartDataModel = new ChartDataModel();
    public threadChartData: ChartDataModel = new ChartDataModel();
    public pendingChartData: ChartDataModel = new ChartDataModel();
    public workChartData: ChartDataModel = new ChartDataModel();
    public gcMemChartData: ChartDataModel = new ChartDataModel();
    public gcPerGenChartData: ChartDataModel = new ChartDataModel();
    public dbLocalTime: Date | string = '';
    public outboundIp: string = '';

    public loadCharts() {
        initChartData(this.cpuChartData, ['% общо', '% OS'], [this.environment.cpuLoadP, this.environment.cpuOsLoadP]);
        initChartData(
            this.poolChartData,
            ['pooled', 'running', 'I/O'],
            [this.environment.poolSize, this.environment.workerThreads, this.environment.ioThreads]
        );
        initChartData(
            this.threadChartData,
            ['общо', 'активни'],
            [this.environment.totalThreads, this.environment.runningThreads]
        );
        this.threadChartData.datasets[1].yAxisID = 'yRight';
        initChartData(this.pendingChartData, ['опашка'], [this.environment.pendingWork]);
        initChartData(
            this.workChartData,
            ['бр/s', 'MB/s'],
            [this.environment.workPerSecond, this.environment.mbPerSecond]
        );
        this.workChartData.datasets[1].yAxisID = 'yRight';
        initChartData(
            this.gcMemChartData,
            ['MB committed', 'MB allocated', 'MB heap', 'MB fragmented'],
            [
                this.environment.committedMb,
                this.environment.allocatedMb,
                this.environment.heapMb,
                this.environment.fragmentedMb
            ]
        );
        const { gcPerGeneration } = this.environment;
        if (gcPerGeneration) {
            initChartData(
                this.gcPerGenChartData,
                gcPerGeneration.map((item: number[], index: number) => `gen ${index}`),
                gcPerGeneration
            );
        }
    }
}
