<template>
    <div>
        <btn action="Save" class="mt-3" :disabled="!examId" @click="getData">Извличане на данни от МДД</btn>
        <alert v-if="!examId" type="info">Бутонът става активен след записване на картата.</alert>
        <h3>МДИ ПРИ ПРОФИЛАКТИЧЕН ПРЕГЛЕД</h3>
        <label>
            За лица със сърдечно-съдови заболявания, захарен диабет, ХБН, които подлежат на оценка на риска по SCORE
            (мъже 40-65 г. и жени 50-65 г.):
        </label>
        <div class="mt-3">
            <text-field
                v-model.number="value.mdiTh"
                label="Общ холестерол"
                :rules="[$validator.minValue(0), $validator.maxValue(99.99)]"
                @blur="calculateLdl"
            />
            <text-field
                v-model.number="value.mdiHdl"
                label="HDL-холестерол"
                :rules="[$validator.minValue(0), $validator.maxValue(99.99)]"
                @blur="calculateLdl"
            />
            <text-field
                v-model.number="value.mdiTg"
                label="Триглицериди"
                :rules="[$validator.minValue(0), $validator.maxValue(99.99)]"
                @blur="calculateLdl"
            />
            <text-field
                v-model="value.mdiNonHdl"
                label="Non-HDL-холестерол(изчислен)"
                :rules="[$validator.minValue(0), $validator.maxValue(99.99)]"
            />
            <text-field
                v-model="value.mdiLdl"
                label="LDL-холестерол(изчислен)"
                :rules="[$validator.minValue(0), $validator.maxValue(99.99)]"
            />
            <text-field
                v-model="value.mdiGlucose"
                label="Кръвна захар (при FINDRISK >= 12)"
                :rules="[$validator.minValue(0), $validator.maxValue(99.99)]"
            />
            <text-field
                v-model="value.mdiPsa"
                :disabled="!isMale"
                label="PSA"
                :rules="[$validator.minValue(0), $validator.maxValue(999.99)]"
            />
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { GenderCode } from '@/enum/Nomenclature/GenderCode';
    import { MedicalDiagnosticTestDto } from '@/model/Exam/Gp/RiskAssessmentCard/MedicalDiagnosticTestDto';
    import { riskAssessmentCardService } from '@/service/Exam/Gp/RiskAssessmentCardService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';

    @Component
    export default class MedicalDiagnosticTestCard extends Vue {
        // Този компонент може да получи undefined v-model. В този случай се bind-ва default стойността, която е празен обект.
        @Prop({ type: Object })
        private value!: MedicalDiagnosticTestDto;

        @Prop()
        private examId!: number;

        private calculateNonHdl() {
            if (this.value.mdiHdl && this.value.mdiTh) {
                const decimalPlaces = 2;
                return (this.value.mdiNonHdl = parseFloat(
                    (Number(this.value.mdiTh) - Number(this.value.mdiHdl)).toFixed(decimalPlaces)
                ));
            }
            return 0;
        }

        private calculateLdl() {
            //Изчисляваме Ldl холестерола според формулата на Vujovic
            const vujovicConstant = 3;
            const hundred = 100;

            const nonHdl = this.calculateNonHdl();

            if (this.value.mdiTg) {
                const decimalPlaces = 2;
                this.value.mdiLdl = nonHdl - Number(this.value.mdiTg) / vujovicConstant;
                return (this.value.mdiLdl = parseFloat(
                    (Math.round(this.value.mdiLdl * hundred) / hundred).toFixed(decimalPlaces)
                ));
            }
            return 0;
        }

        private get isMale() {
            return currentPatientCache.value.personIdentification.genderCode === GenderCode.Male;
        }

        private async getData() {
            this.$loading.show();
            try {
                const response = (await riskAssessmentCardService.getMedicalDiagnosticTestResult(this.examId)).data;

                if (response) {
                    this.value.mdiTh = response.mdiTh;
                    this.value.mdiHdl = response.mdiHdl;
                    this.value.mdiTg = response.mdiTg;
                    this.value.mdiNonHdl = response.mdiNonHdl;
                    this.value.mdiLdl = response.mdiLdl;
                    this.value.mdiGlucose = response.mdiGlucose;
                    this.value.mdiPsa = response.mdiPsa;
                    this.$notifier.showSuccess('', 'Данните са извлечени успешно');
                }
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>
